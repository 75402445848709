import React from "react";
import PropTypes from "prop-types";
import { Drawer, Menu } from "antd";

import useQueryParams from "../../hooks/useQueryParams";

const MobileDropdown = ({
  isDrawerOpened,
  closeDrawer,
  drawerChildren,
  queryToSet,
  defaultSelected,
}) => {
  const [, setQueryParams] = useQueryParams();
  const isCategoryFilter = !queryToSet;

  const handleClickDropdownOption = (value) => {
    const queryParams = queryToSet === "status" || queryToSet === "platform" ?
      { [queryToSet]: value, page: 1, ordering: value === "SOLD" ? "-sold_date" : "" } :
      { [queryToSet]: value, page: 1 };

    setQueryParams(queryParams);
    closeDrawer();
  };

  return (
    <Drawer
      open={isDrawerOpened}
      className={`dropdown-drawer ${isCategoryFilter ? "category-filter" : ""}`}
      onClose={closeDrawer}
      placement="bottom"
      closable={false}
    >
      <Menu selectedKeys={[defaultSelected]}>
        {drawerChildren.map(item => {
          return (
            <Menu.Item key={`${item.title}-${item.value}`} onClick={() => handleClickDropdownOption(item.value)}>
              {item.label}
              {item.itemsCount && (
                <span>{item.itemsCount}</span>
              )}
            </Menu.Item>
          )
        })}
      </Menu>
    </Drawer>
  )
};

MobileDropdown.propTypes = {
  isDrawerOpened: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  drawerChildren: PropTypes.array,
  queryToSet: PropTypes.string,
  defaultSelected: PropTypes.string.isRequired
};

export default MobileDropdown;
